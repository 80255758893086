import Vue from 'vue';

// v-dialogDrag: 弹窗拖拽属性
Vue.directive('dialogDrag', {
    // bind(el, binding, vnode, oldVnode) {
    bind(el) {
        const dialogHeaderEl = el.querySelector('.el-dialog__header');
        const dragDom = el.querySelector('.el-dialog');
        dialogHeaderEl.style.cssText += ';cursor:move;'
        dragDom.style.cssText += ';top:0px;'

        // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
        const sty = (() => {
            if (window.document.currentStyle) {
                return (dom, attr) => dom.currentStyle[attr];
            } else {
                return (dom, attr) => getComputedStyle(dom, false)[attr];
            }
        })()

        dialogHeaderEl.onmousedown = (e) => {
            // 鼠标按下，计算当前元素距离可视区的距离
            const disX = e.clientX - dialogHeaderEl.offsetLeft;
            const disY = e.clientY - dialogHeaderEl.offsetTop;

            const screenWidth = document.body.clientWidth; // body当前宽度
            const screenHeight = document.documentElement.clientHeight; // 可见区域高度(应为body高度，可某些环境下无法获取)

            const dragDomWidth = dragDom.offsetWidth; // 对话框宽度
            const dragDomheight = dragDom.offsetHeight; // 对话框高度

            const minDragDomLeft = dragDom.offsetLeft;
            const maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth;

            const minDragDomTop = dragDom.offsetTop;
            const maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomheight;


            // 获取到的值带px 正则匹配替换
            let styL = sty(dragDom, 'left');
            let styT = sty(dragDom, 'top');

            // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
            if (styL.includes('%')) {
                // eslint-disable-next-line no-useless-escape
                styL = +document.body.clientWidth * (+styL.replace(/\%/g, '') / 100);
                // eslint-disable-next-line no-useless-escape
                styT = +document.body.clientHeight * (+styT.replace(/\%/g, '') / 100);
            } else {
                styL = +styL.replace(/\px/g, '');
                styT = +styT.replace(/\px/g, '');
            }

            document.onmousemove = function (e) {
                // 通过事件委托，计算移动的距离
                let left = e.clientX - disX;
                let top = e.clientY - disY;

                // 边界处理
                if (-(left) > minDragDomLeft) {
                    left = -(minDragDomLeft);
                } else if (left > maxDragDomLeft) {
                    left = maxDragDomLeft;
                }

                if (-(top) > minDragDomTop) {
                    top = -(minDragDomTop);
                } else if (top > maxDragDomTop) {
                    top = maxDragDomTop;
                }

                // 移动当前元素 
                dragDom.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`;
            };

            document.onmouseup = function () {
                document.onmousemove = null;
                document.onmouseup = null;
            };
        }
    }
});
// Vue.directive("drag", {
//     bind: function (el) {
//         let odiv = el;

//         odiv.style.position = "fixed";
//         odiv.onmousedown = (e) => {
//             let disX = e.clientX - odiv.offsetLeft;
//             let disY = e.clientY - odiv.offsetTop;
//             document.onmousemove = (e) => {
//                 let left = e.clientX - disX;
//                 let top = e.clientY - disY;
//                 odiv.style.left = left + "px";
//                 odiv.style.top = top + "px";
//             };
//             document.onmouseup = () => {
//                 document.onmousemove = null;
//                 document.onmouseup = null;
//             };
//         };
//     },
// });
// Vue.directive('drag', {
//     //1.指令绑定到元素上回立刻执行bind函数，只执行一次
//     //2.每个函数中第一个参数永远是el，表示绑定指令的元素，el参数是原生js对象
//     //3.通过el.focus()是无法获取焦点的，因为只有插入DOM后才生效
//     bind: function (el) { },
//     //inserted表示一个元素，插入到DOM中会执行inserted函数，只触发一次
//     inserted: function (el) {
//         console.log(el);
//       let odiv = el; //获取当前元素
//       console.log(odiv);
//       let firstTime = '', lastTime = '';
//       el.onmousedown = function (e) {
//         var disx = e.pageX - el.offsetLeft;
//         var disy = e.pageY - el.offsetTop;
//         // 给当前元素添加属性，用于元素状态的判断
//         odiv.setAttribute('ele-flag', false)
//         odiv.setAttribute('draging-flag', true)
//         firstTime = new Date().getTime();
//         document.onmousemove = function (e) {
//           el.style.left = e.pageX - disx + 'px';
//           el.style.top = e.pageY - disy + 'px';
//         }
//         document.onmouseup = function (event) {
//           document.onmousemove = document.onmouseup = null;
//           lastTime = new Date().getTime();
//           if ((lastTime - firstTime) > 200) {
//             odiv.setAttribute('ele-flag', true)
//             event.stopPropagation()
//           }
//           setTimeout(function () {
//             odiv.setAttribute('draging-flag', false)
//           }, 100)
//         }
//       }
//     }
//   })
// Vue.directive('drag', (el, bindings, vnode) => {
Vue.directive('drag', (el) => {
    Vue.nextTick(() => {
        let modal = el;
        let header = el.getElementsByClassName('dialog-content-title')[0];
        let firstTime = '', lastTime = '';
        header.onmousedown = e => {
            var disx = e.pageX - el.offsetLeft;
            var disy = e.pageY - el.offsetTop;
            // 给当前元素添加属性，用于元素状态的判断
            modal.setAttribute('ele-flag', false)
            modal.setAttribute('draging-flag', true)
            firstTime = new Date().getTime();
            document.onmousemove = function (e) {
                el.style.left = e.pageX - disx + 'px';
                el.style.top = e.pageY - disy + 'px';
            }
            document.onmouseup = function (event) {
                document.onmousemove = document.onmouseup = null;
                lastTime = new Date().getTime();
                if ((lastTime - firstTime) > 200) {
                    modal.setAttribute('ele-flag', true)
                    event.stopPropagation()
                }
                setTimeout(function () {
                    modal.setAttribute('draging-flag', false)
                }, 100)
            }
        }
    })
})




