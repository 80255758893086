import Vue from "vue";
import Router from "vue-router";
import store from "../store/store";
// import { SYS_TITLE } from '@/constvars';
import Nprogress from "nprogress";
import cookie from "js-cookie";

Vue.use(Router);
const originalPush = Router.prototype.push;
//修改原型对象中的push方法解决点击相同的菜单页报错重复点击
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = new Router({
  mode: "hash",
  /**
   * router的配置：
   * 为了方便显示面包线的内容，需有层级关系，（目前最多有三层）
   * 所有的页面都在首页下
   *
   * 如有第三级页面，他的父级（也就是二级）也需要有path、name和路径
   * path和name可以随便取名（不冲突就可以），路径为page文件夹下的Container.vue
   *
   * 不需要显示到面包线内的页面，需写到首页的同级，例如登录页
   */
  routes: [
    {
      path: "/",
      name: "main",
      redirect: "/index",
    },
    {
      path: "/home",
      name: "home",
      component: () =>
        import(/* webpackChunkName: "home" */ "../components/common/home.vue"),
      meta: { title: "首页" },
      redirect: "/index",
      children: [
        {
          path: "/index",
          name: "index",
          component: () =>
            import(/* webpackChunkName: "Dashboard" */ "../views/index.vue"),
          meta: { title: "首页" },
        },
        {
          path: "/details",
          name: "details",
          component: () =>
            import(
              /* webpackChunkName: "Dashboard" */ "../views/demo/index.vue"
            ),
          meta: { title: "手术远程指导会诊中心" },
        },
        {
          path: "/preoperativeAssess",
          name: "preoperativeAssess",
          component: () =>
            import(
              /* webpackChunkName: "Dashboard" */ "../views/userDetails/preoperativeAssess"
            ),
          meta: { title: "患者信息" },
        },
        {
          path: "/preoperativeInspect",
          name: "preoperativeInspect",
          component: () =>
            import(
              /* webpackChunkName: "Dashboard" */ "../views/userDetails/preoperativeInspect"
            ),
          meta: { title: "患者信息" },
        },
        {
          path: "/personalDetails",
          name: "personalDetails",
          component: () =>
            import(
              /* webpackChunkName: "Dashboard" */ "../views/userDetails/personalDetails"
            ),
          meta: { title: "患者信息" },
        },
        {
          path: "/dicomplayer",
          name: "dicomplayer",
          component: () =>
            import(
              /* webpackChunkName: "Dashboard" */ "../views/userDetails/dicomPlayer"
            ),
          meta: { title: "PACS影像" },
        },
        {
          path: "/shareImages",
          name: "shareImages",
          component: () =>
            import(
              /* webpackChunkName: "Dashboard" */ "../views/userDetails/shareImages"
            ),
          meta: { title: "共享图片" },
        },
        {
          path: "/sqAssess",
          name: "sqAssess",
          component: () =>
            import(
              /* webpackChunkName: "Dashboard" */ "../views/userDetails/preoperativeAssess"
            ),
          meta: { title: "术前评估" },
        },
        {
          path: "/sqInspect",
          name: "sqInspect",
          component: () =>
            import(
              /* webpackChunkName: "Dashboard" */ "../views/userDetails/preoperativeInspect"
            ),
          meta: { title: "术前检查" },
        },
        {
          path: "/patientmessage",
          name: "patientmessage",
          component: () =>
            import(
              /* webpackChunkName: "Dashboard" */ "../views/userDetails/personalDetails"
            ),
          meta: { title: "患者详细信息" },
        },
      ],
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "login" */ "../views/Login.vue"),
      meta: { title: "登录" },
    },
    {
      path: "/register",
      component: () =>
        import(/* webpackChunkName: "login" */ "../views/Register.vue"),
      meta: { title: "注册" },
    },
    {
      path: "/404",
      component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
      meta: { title: "404" },
    },
    {
      path: "*",
      redirect: "/404",
    },
  ],
});

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
  Nprogress.start();

  // document.title = `手术远程指导会诊中心`;
  // console.log("如需拦截路由请修改 router.js 的 router.beforeEach");
  next();
  let token = store.state.token;
  let allow_path = [
    "/register",
    "/login",
    // "/registerSuccess",
    "/404",
  ];
  if (!token && allow_path.indexOf(to.path) === -1) {
    if (
      to.path != "/details" &&
      to.path != "/index" &&
      to.path != "/login" &&
      to.query["cqt-token"]
    ) {
      // console.log(to);
      // console.log(from);
      // console.log(to.query['cqt-token']);
      document.title = `${to.meta.title}`;
      cookie.set("cqt-token", to.query["cqt-token"], { domain: "localhost" });
      cookie.set("cqt-token", to.query["cqt-token"], {
        domain: "rmcc.loongreen.com",
      });
      next();
    } else {
      next({
        name: "login",
      });
    }
  } else {
    document.title = `${to.meta.title}`;
    next();
  }
});

router.afterEach(() => {
  Nprogress.done();
});

export default router;
