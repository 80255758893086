export const BASE_HOST = "http://main.test.nmhuixin.com";

export const SYS_TITLE = "手术远程指导会诊中心";

export const SYS_ID = 12;
if (SYS_ID === null) {
  alert("请在constvars填写SYS_ID!");
  throw Error("SYS_ID 未配置！");
}

export const PREDEFINE_COLORS = [
  "#e41e1e",
  "#c71585",
  "#ffa200",
  "#ffd700",
  "#90ee90",
  "#05cfbe",
  "#1cc2db",
  "#1e90ff",
  "#648bf5",
  "#014bb1",
];
export const ZGGL_TMPL_ID = "";

export const TMPL_ID = {
  ZGGL_TMPL_ID,
};

export default {
  BASE_HOST,
  SYS_TITLE,
  PREDEFINE_COLORS,
  TMPL_ID,
};
