import Vue from "vue";
import router from "./router/router";
import store from "./store/store";
import ElementUI from "element-ui";
import EventBus from "@/utils/bus.js";
import App from "./App.vue";
import "./utils/direction";

import "./utils/hammer.min.js";
import * as cornerstone from "cornerstone-core";
import * as dicomParser from "dicom-parser";
import * as cornerstoneTools from "cornerstone-tools";
import * as cornerstoneMath from "cornerstone-math";
import "nprogress/nprogress.css";
import "element-ui/lib/theme-chalk/index.css"; // 默认主题
import "./assets/css/image-button.less";
import "./config/rem";
import "../src/assets/js/directive.js"; // 拖拽弹窗，在需要用到拖拽功能的弹窗标签上加v-dialogDrag

import "./assets/icon/iconfont.js"; //标注页面的图标
// import "./utils/directives";
import CONSTVARS, { SYS_TITLE } from "./utils/constvars";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader/dist/cornerstoneWADOImageLoaderNoWebWorkers.bundle.min";

import * as echarts from "echarts";
import vueEsign from "vue-esign";
// import "./resource";

Vue.prototype.echarts = echarts;
Vue.use(echarts);
Vue.use(vueEsign);
Vue.prototype.$EventBus = new Vue();
Vue.config.productionTip = false;
Vue.prototype.$bus = EventBus;
Vue.prototype.CVARS = CONSTVARS;
Vue.prototype.SYS_TITLE = SYS_TITLE;


// 配置websorcket连接地址
Vue.prototype.debug = false;


Vue.use(ElementUI, {
  size: "small",
});
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneWADOImageLoader.external.cornerstoneMath = cornerstoneMath;

/*
 *配置 webWorker (必须配置)
 */
cornerstoneWADOImageLoader.configure({
  beforeSend: function () {
    // Add custom headers here (e.g. auth tokens)
    //xhr.setRequestHeader('APIKEY', 'my auth token');
  },
});
var config = {
  maxWebWorkers: navigator.hardwareConcurrency || 1,
  startWebWorkersOnDemand: true,
  // webWorkerPath: ' ../../ dist / cornerstoneWADOImageLoaderWebWorker.js ',
  taskConfiguration: {
    decodeTask: {
      initializeCodecsOnStartup: false,
      // codecsPath : ' ../ dist / cornerstoneWADOImageLoaderCodecs.js ',
    },
    sleepTask: {
      sleepTime: 3000,
    },
  },
};
cornerstoneWADOImageLoader.webWorkerManager.initialize(config);

cornerstoneTools.init();
cornerstoneTools.toolColors.setToolColor("yellow");

Vue.prototype.$cornerstone = cornerstone;
Vue.prototype.$dicomParser = dicomParser;
Vue.prototype.$cornerstoneTools = cornerstoneTools;
Vue.prototype.$cornerstoneWADOImageLoader = cornerstoneWADOImageLoader;
if (process.env.NODE_ENV === "production") {
  console.log = function () {};
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
