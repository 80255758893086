import Vue from 'vue'
import Clickoutside from 'element-ui/src/utils/clickoutside'
Vue.directive('clickoutside', Clickoutside)
function throttle (func, delay) {
  var timer = null
  return function () {
    var context = this
    var args = arguments
    if (!timer) {
      timer = setTimeout(function () {
        func.apply(context, args)
        timer = null
      }, delay)
    }
  }
}
function debounce (fn, delay) {
  let timer = null
  let f = false
  return function () {
    var context = this
    var args = arguments
    if (!f) {
      fn.apply(context, args)
      f = true
      timer = setTimeout(() => {
        f = false
      }, delay)
    } else {
      clearTimeout(timer)
      timer = setTimeout(() => {
        clearTimeout(timer)
        f = false
      }, delay)
    }
  }
}
// debounce
Vue.directive('debounce', {
  bind (el, binding) {
    let execFunc
    if (binding.value instanceof Array) {
      const [func, time = 500] = binding.value
      execFunc = debounce(func, time)
    } else {
      execFunc = debounce(binding.value, 500)
    }
    el.addEventListener('click', execFunc)
  }
})
// throttle
Vue.directive('throttle', {
  bind (el, binding) {
    let execFunc
    if (binding.value instanceof Array) {
      const [func, time = 500] = binding.value
      execFunc = throttle(func, time)
    } else {
      execFunc = throttle(binding.value, 500)
    }
    el.addEventListener('click', execFunc)
  }
})

Vue.directive('visibility', {
  bind (el) {
    el.style.visibility = 'hidden'
  },
  update (el, binding) {
    let res = binding.value ? 'visible' : 'hidden'
    el.style.visibility = res
  }
})

Vue.directive('port', {
  bind (el, binding) {
    let hostName = window.location.hostname
    let port = window.location.port
    if (hostName === 'localhost') {
      el.src
    } else {
      el.src = el.src.split(port).join(binding.value)

    }
  },
  update (el, binding, vnode, oldVnode) {
    let hostName = window.location.hostname
    let port = window.location.port
    if (vnode.data?.attrs.src === oldVnode.data?.attrs.src) {
      return
    }
    if (hostName === 'localhost') {
      el.src
    } else {
      el.src = el.src.split(port).join(binding.value)
    }
  },
})
