import Vue from "vue";
import Vuex from "vuex";
import SecureLS from "secure-ls";
import createPersistedState from "vuex-persistedstate";
import { SYS_ID } from "../utils/constvars";
Vue.use(Vuex);

let ls = new SecureLS({ isCompression: false });

const getDefaultState = () => {
  return {
    token: "",
    sys_id: SYS_ID,
    org_id: 0,
    openid: null,
    userinfo: {
      pk: null,
      sys_id: SYS_ID,
      username: "",
      mobile: "",
      department_id: null,
      is_active: false,
      is_superuser: false,
      full_name: "",
      func_names: [],
      func_codenames: [],
      func_group_names: [],
    },
    systemlist: [],
    detailUser: "",
    patientDetails: {},
    userInfo: {},
    playVideoUrl: "",
    playAudioUrl: "",
    start_num: 1,
    finish_num: 1,
    num: 10,
    showDate: false,
    showStatisticsMessage: false, //日程安排显示隐藏
    showHeaderStatus: false, //header栏显示隐藏
    showLabelStatus: false,
    labelHistory: false, //标注记录弹窗
    closeMeeting: false, //关闭会议
    falseJoinRoom: false
  };
};

const state = getDefaultState();

const actions = {};

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const USERINFO = "USERINFO";
export const saveDetailUser = "saveDetailUser";
export const patientDetails = "patientDetails";
export const userInfo = "userInfo";
export const playVideoUrl = "playVideoUrl";
export const playAudioUrl = "playAudioUrl";
export const showDate = "showDate";
export const showStatisticsMessage = "showStatisticsMessage";
export const showHeaderStatus = "showHeaderStatus";
export const showLabelStatus = "showLabelStatus";
export const labelHistory = "labelHistory";
export const closeMeeting = "closeMeeting";
export const falseJoinRoom = "falseJoinRoom";

const mutations = {
  [USERINFO](state, userinfo) {
    state.userinfo = userinfo;
    state.org_id = userinfo.org_id;
  },
  [LOGIN](state, token) {
    state.token = token;
  },
  [LOGOUT](state) {
    Object.assign(state, getDefaultState());
  },
  [saveDetailUser](state, newState) {
    state.detailUser = newState;
  },
  [patientDetails](state, newState) {
    state.patientDetails = newState;
  },
  [userInfo](state, newState) {
    state.userInfo = newState;
  },
  [playVideoUrl](state, newState) {
    state.playVideoUrl = newState;
  },
  [playAudioUrl](state, newState) {
    state.playAudioUrl = newState;
  },
  [showDate](state, newState) {
    state.showDate = newState;
  },
  [showStatisticsMessage](state, newState) {
    state.showStatisticsMessage = newState;
  },
  [showHeaderStatus](state, newState) {
    state.showHeaderStatus = newState;
  },
  [showLabelStatus](state, newState) {
    state.showLabelStatus = newState;
  },
  [labelHistory](state, newState) {
    state.labelHistory = newState;
  },
  [closeMeeting](state, newState) {
    state.closeMeeting = newState;
  },
  [falseJoinRoom](state, newState) {
    state.falseJoinRoom = newState;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
